<template>
  <div class="main">
    <div class="container">
      <div class="news_header">
        <div class="title">Cookies Policy</div>
      </div>
      <div class="news_detail">
        <div class="text">
          The first time that you visit our website(s), we notify you about our
          use of Cookies through a notification banner. By continuing to use our
          website(s), you consent to our use of Cookies as described in this
          Cookie Policy. However, you can choose whether or not to continue
          accepting Cookies at any later time. <br />Please note that this
          Cookies Policy may be updated at any time without notice by posting a
          revised version on our website(s). Every time you wish to use our
          website(s), please check this Cookies Policy to ensure that you are
          aware of any changes.

          <br /><br />
          <div class="t2">Cookies</div>
          When you visit our website(s), we use “cookie” technology try to make
          that experience simple, personalized and meaningful. Cookies are small
          pieces of information which are issued to your computer or any similar
          device you use to access the internet e.g. smart phone, tablet or
          other mobile device when you visit a website and which store and
          sometimes track information about your use of the website. A number of
          cookies we use last only for the duration of your web session and
          expire when you close your browser. Other cookies are used to remember
          you when you return to the website and will last for longer. We use
          cookies to:<br />
          <br />create a specific log-in session for a visitor to our website(s)
          in order that page requests by that visitor are delivered in an
          effective, secure and consistent manner; <br />recognize when a
          visitor to our website(s) has visited before; this means we can
          identify the number of unique visitors we receive to our website(s)
          and allows us to make sure we have enough capacity for the number of
          users that we get; <br />customize elements of the promotional layout
          and/or content of the pages of our website(s); <br />collect
          statistical information about how our visitors use our website(s) so
          that we can improve our website(s) and learn which parts are most
          popular to visitors. <br />store your product selections to the
          shopping basket; and <br />gather information through our advertising
          partners, about the pages on our website(s) that you visit, and also
          other information about other websites that you visit, so as to place
          you in an “interest segment”. This information is then used to serve
          interest- based advertisements which it is believed will be targeted
          to your interests. Without these cookies, advertisements you encounter
          will be less relevant to you and to your interests. Some of the
          cookies used on our website(s) are set by us, and some are set by
          third parties who are delivering services on our behalf. Most web
          browsers automatically accept cookies but, if you prefer, you can
          change your browser to prevent that or to notify you each time a
          cookie is set. You can also learn more about cookies by visiting
          www.allaboutcookies.org which includes additional useful information
          on cookies and how to block cookies using different types of browser.
          Please note however, that by blocking or deleting cookies used on our
          website(s) you may not be able to take full advantage of our
          website(s) if you do so. <br /><br />

          <div class="t2">Our cookies</div>
          Third party cookies<br />
          Certain cookies are either set by third parties on our website(s) or
          these are set by us using the third parties cookie code, mostly to
          provide web analytics and intelligence about our sites and which may
          also be used to provide measurement services and target ads. These
          companies use programming code to collect information about your
          interactions, such as the pages you visit, the links you click on and
          how long you visit our sites. More information about cookies placed by
          third parties and how to restrict or block their cookies can be
          accessed by following the link to their website. In addition to
          cookies, tracking gifs may be set by us or third parties. Tracking
          gifs are small image files within the content of our site or the body
          of our newsletters so we or third parties can understand which parts
          of our website(s) are visited or whether particular content is of
          interest.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'text',
  components: {},
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
@import '@/assets/styles/news.scss';
</style>
